/* eslint-disable */

import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlRequest)

export class RequestService {
  static async fetchRequests(pagination, filter, orderBy = 'createdAt') {
    const response = await graphqlClient.query({
      query: gql`
        query REQUEST_LIST(
          $filter: RequestFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          requestList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            count
            pagination {
              isFirstPage
              isLastPage
            }
            rows {
              id
              menusNeeded
              status
              requester {
                id
                fullName
                email
              }
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        pagination,
        filter,
        orderBy
      }
    })
    return response.data.requestList
  }

  static async acceptRequest(id, data) {
    await graphqlClient.mutate({
      mutation: gql`
        mutation REQUEST_ACCEPT($id: String!, $data: RequestInput!) {
          requestAccept(id: $id, data: $data)
        }
      `,
      variables: {
        id,
        data
      }
    })
  }

  static async rejectRequest(id, data) {
    await graphqlClient.mutate({
      mutation: gql`
        mutation REQUEST_REJECT ($id: String!, $data: RequestInput!) {
          requestReject(id: $id, data: $data)
        }
      `,
      variables: {
        id,
        data
      }
    })
  }
}
