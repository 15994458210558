/* eslint-disable */
import Errors from '@/shared/error/errors'
import { MenuService } from '@/store/menus/menus-service'

const INITIAL_PAGE_SIZE = 10
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  // orderBy: 'createdAt',
  sortBy: 'desc'
  // action: 'next',
  // firstPage: true,
  // doc: {}
}

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    currentPage: 1,
    pagination: INITIAL_PAGINATION_INPUT,
    sorter: {},
    filter: {}
  },
  getters: {
    loading: state => state.loading,
    rows: state => state.rows || [],
    count: state => state.count,
    hasRows: (_, getters) => getters.count > 0,
    currentPage: state => state.currentPage,
    filter: state => state.filter,
    // orderBy: state => {
    //   const sorter = state.sorter

    //   if (!sorter || !sorter.prop) return null

    //   let direction = sorter.order === 'descending' ? 'DESC' : 'ASC'

    //   return `${sorter.prop}_${direction}`
    // },
    limit: state => {
      const pagination = state.pagination
      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE
      }
      return pagination.pageSize
    },
    // offset: state => {
    //   const pagination = state.pagination
    //   if (!pagination || !pagination.pageSize) return 0
    //   const currentPage = pagination.currentPage || 1
    //   return (currentPage - 1) * pagination.pageSize
    // },
    pagination: (state, getters) => {
      return {
        ...state.pagination,
        currentPage: state.currentPage,
        total: getters.count,
        showSizeChanger: true
      }
    }
  },
  mutations: {
    FETCH_STARTED: (state, payload) => {
      state.loading = true
      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.pagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.pagination
          : {}
    },
    FETCH_SUCCESS: (state, payload) => {
      state.loading = false
      state.rows = payload.rows
      state.count = payload.count
    },
    FETCH_ERROR: state => {
      state.loading = false
      state.rows = []
      state.count = 0
    },
    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage =
        payload && payload > 0 ? payload : state.currentPage || 1
    },
    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT

      state.currentPage = state.currentPage || 1
      state.pagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
      }
    }
  },
  actions: {
    doFetch: async (
      { commit, getters },
      { pagination, filter, keepPagination = true }
    ) => {
      try {
        commit('FETCH_STARTED', { filter, pagination, keepPagination })
        const PaginationInput = {
          limit: pagination.limit || getters.pagination.limit,
          // doc: pagination.doc || getters.pagination.doc,
          // action: pagination.action || getters.pagination.action,
          // orderBy: pagination.orderBy || getters.pagination.orderBy,
          sortBy: pagination.sortBy || getters.pagination.sortBy
          // firstPage: pagination.firstPage
        }
        const response = await MenuService.getMenus(
          PaginationInput,
          getters.filter
        )
        console.log('{{RESPONSE}} => ', response)
        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.rows.length
        })
      } catch (err) {
        Errors.handle(err)
        commit('FETCH_ERROR')
      }
    },
    async doFetchNextPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      if (rows.length) {
        const currentPage = getters.currentPage || 1
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const lastDocument = rows[rows.length - 1]
        const pagination = {
          ...previousPagination,
          action: 'next',
          firstPage: false,
          doc: lastDocument
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage + 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetchPreviousPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      const currentPage = getters.currentPage || 1
      if (currentPage > 1 && rows.length) {
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const firstDocument = rows[0]
        const pagination = {
          ...previousPagination,
          action: 'prev',
          firstPage: false,
          doc: firstDocument
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage - 1)
        await dispatch('doFetch', { pagination })
      }
    },
    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize)
      const filter = getters.filter
      const pagination = getters.pagination
      dispatch('doFetch', { filter, pagination, keepPagination: true })
    },

    doChangePaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
      // const filter = getters.filter;
      // dispatch('doFetch', { filter, keepPagination: true });
    }
  }
}
