import { createStore } from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import main from './main'
import sideMenu from './side-menu'
import auth from '@/auth/auth-store'
import layout from '@/store/layout-store'
import user from '@/store/user/user-store'
import request from '@/store/request/request-store'
import menus from '@/store/menus/menus-store'

const store = createStore({
  modules: {
    main,
    sideMenu,
    auth,
    layout,
    user,
    request,
    menus
  },
  mutations: {
    ...vuexfireMutations
  }
})

export function useStore() {
  return store
}

export default store
