/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlMenu)

export class MenuService {
  static async getMenus(pagination, filter, orderBy = 'createdAt') {
    const response = await graphqlClient.query({
      query: gql`
        query MENU_LIST(
          $filter: MenuFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          menuList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            count
            pagination {
              isFirstPage
              isLastPage
            }
            rows {
              id
              name {
                en
                ar
              }
              file
              accountId
              owner {
                fullName
                phoneNumber
                email
              }
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        pagination,
        filter,
        orderBy
      }
    })
    return response.data.menuList
  }
}
