/* eslint-disable */
import { UserService } from '@/store/user/user-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'
import { FileUploader } from '@/shared/firebase/file-uploader'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },

    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    ADD_STARTED(state) {
      state.saveLoading = true
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false
    },

    ADD_ERROR(state) {
      state.saveLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await UserService.find(id)
        commit('FIND_SUCCESS', record)
      } catch (err) {
        Errors.handle(err)
        commit('FIND_ERROR')
        router.push('/users')
      }
    },

    async doAdd({ commit, dispatch, rootGetters }, values) {
      try {
        // const currentUser = rootGetters[`auth/currentUser`]
        // const path = `users/${currentUser.id}/profile`

        commit('ADD_STARTED')
        // const { publicUrl } = await FileUploader.upload(path, file)
        // values.avatar = publicUrl
        values.maxNumberOfMenus = +values.maxNumberOfMenus
        await UserService.createUser(values)
        commit('ADD_SUCCESS')
        dispatch('auth/doRefreshCurrentUser', {}, { root: true })
        Message.success(i18n('user.create.success'))
        router.push('/')
      } catch (err) {
        Errors.handle(err)
        commit('ADD_ERROR')
        router.push('/')
      }
    }

    // async doUpdate({ commit, rootGetters, dispatch }, { id, values }) {
    //   try {
    //     commit('UPDATE_STARTED')
    //     await UserService.editUser(id, values)
    //     commit('UPDATE_SUCCESS')
    //     const currentUser = rootGetters['auth/currentUser']
    //     if (currentUser.id === id) {
    //       dispatch('auth/doRefreshCurrentUser')
    //     }
    //     Message.success(i18n('user.update.success'))
    //     router.push('/users')
    //   } catch (err) {
    //     Errors.handle(err)
    //     commit('UPDATE_ERROR')
    //   }
    // }
  }
}
