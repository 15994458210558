/* eslint-disable */

// import graphqlClient from '@/shared/graphql/client'
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlIam)

export class UserService {
  static async createUser(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_REGISTER($data: UserRegisterInput!) {
          userRegister(data: $data)
        }
      `,
      variables: {
        data
      }
    })
    console.log('DATA ==>>', data)
    return response.data.userRegister
  }

  static async fetchUsers(filter, pagination, orderBy = 'createdAt') {
    const response = await graphqlClient.query({
      query: gql`
        query USER_LIST(
          $filter: JSON
          $orderBy: String
          $pagination: PaginationInput
        ) {
          userList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            count
            pagination {
              isFirstPage
              isLastPage
            }
            rows {
              id
              fullName
              avatar
              email
              hostingURL
              maxNumberOfMenus
              numberOfMenusUsed
              disabled
              tempPassword
              phoneNumber
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        filter,
        orderBy,
        pagination
      }
    })
    return response.data.userList
  }

  static async toggleStatus(id, disabled) {
    await graphqlClient.mutate({
      mutation: gql`
        mutation IAM_CHANGE_STATUS($ids: [String!]!, $disabled: Boolean) {
          iamChangeStatus(ids: $ids, disabled: $disabled)
        }
      `,
      variables: {
        ids: [id],
        disabled
      }
    })
  }
  /**
   * Update admin
   * @param {String} id
   * @param {Object} data
   * @param {String} data.email
   * @param {String} data.userName
   * @param {String} data.phoneNumber
   * @param {String[]} data.pagesAccess
   * @param {String} data.avatar
   * @param {'en'|'ar' } data.lang
   * @returns
   */
  // static async editUser(id, data) {
  //   // const response = await graphqlClient.mutate({
  //   //   mutation: gql`
  //   //     mutation USER_EDIT($adminId: String!, $adminUpdate: AdminUpdate!) {
  //   //       editAdmin(adminId: $adminId, adminUpdate: $adminUpdate)
  //   //     }
  //   //   `,
  //   //   variables: {
  //   //     adminId: id,
  //   //     adminUpdate: data
  //   //   }
  //   // })
  //   // if (response.data.editAdmin && response.data.editAdmin.status) {
  //   //   return response.data.editAdmin.result
  //   // } else {
  //   //   console.error(response.data.editAdmin.error)
  //   //   throw response.data.editAdmin.error
  //   // }
  //   // return response.data.editAdmin
  // }

  // /**
  //  * Enable user in authentication table and firestore database
  //  * @param {String[]} ids
  //  * @returns
  //  */
  // static async enable(ids) {
  //   return this._changeStatus(ids, true)
  // }

  // /**
  //  * Disable user in authentication table and firestore database
  //  * @param {String[]} ids
  //  * @returns
  //  */
  // static async disable(ids) {
  //   return this._changeStatus(ids, false)
  // }

  // static async _changeStatus(ids, disabled) {
  //   // const response = await graphqlClient.mutate({
  //   //   mutation: gql`
  //   //     mutation USER_CHANGE_STATUS($adminsIds: [String!]!, $status: Boolean!) {
  //   //       setAdminsStatus(adminsIds: $adminsIds, status: $status)
  //   //     }
  //   //   `,
  //   //   variables: {
  //   //     adminsIds: ids,
  //   //     status: !!disabled
  //   //   }
  //   // })
  //   // // return response.data.setAdminsStatus
  //   // if (response.data.setAdminsStatus && response.data.setAdminsStatus.status) {
  //   //   return response.data.setAdminsStatus.result
  //   // } else {
  //   //   console.error(response.data.setAdminsStatus.error)
  //   //   throw response.data.setAdminsStatus.error
  //   // }
  // }

  // /**
  //  * Set page access permissions for the admin
  //  * @param {String} id
  //  * @param {String[]} pagesAccess
  //  * @returns
  //  */
  // static async setAdminAccess(id, pagesAccess) {
  //   // const response = await graphqlClient.mutate({
  //   //   mutation: gql`
  //   //     mutation USER_CHANGE_STATUS($adminId: String!, $pagesAccess: Boolean) {
  //   //       setAdminAccess(adminId: $adminId, pagesAccess: $pagesAccess)
  //   //     }
  //   //   `,
  //   //   variables: {
  //   //     adminId: id,
  //   //     pagesAccess
  //   //   }
  //   // })
  //   // // return response.data.setAdminAccess
  //   // if (response.data.setAdminAccess && response.data.setAdminAccess.status) {
  //   //   return response.data.setAdminAccess.result
  //   // } else {
  //   //   console.error(response.data.setAdminAccess.error)
  //   //   throw response.data.setAdminAccess.error
  //   // }
  // }

  // static async removeUsers(ids) {
  //   //     const response = await graphqlClient.mutate({
  //   //       mutation: gql`
  //   //         mutation USER_DESTROY($usersIds: [String]!) {
  //   //           removeUsers(usersIds: $usersIds)
  //   //         }
  //   //       `,
  //   //       variables: {
  //   //         usersIds: ids
  //   //       }
  //   //     })
  //   //     // return response.data.removeUsers
  //   //     if (response.data.removeUsers && response.data.removeUsers.status) {
  //   //       return response.data.removeUsers.result
  //   //     } else {
  //   //       console.error(response.data.removeUsers.error);
  //   //       throw response.data.removeUsers.error
  //   //     }
  // }

  // //   // static async remove(emails, roles, all = false) {
  // //   //   const response = await graphqlClient.mutate({
  // //   //     mutation: gql`
  // //   //       mutation USER_REMOVE(
  // //   //         $emails: [String!]!
  // //   //         $roles: [String!]!
  // //   //         $all: Boolean
  // //   //       ) {
  // //   //         iamRemove(
  // //   //           emails: $emails
  // //   //           roles: $roles
  // //   //           all: $all
  // //   //         )
  // //   //       }
  // //   //     `,

  // //   //     variables: {
  // //   //       emails,
  // //   //       roles,
  // //   //       all
  // //   //     }
  // //   //   })

  // //   //   return response.data.iamRemove
  // //   // }

  // //   // static async destroy(id) {
  // //   //   const response = await graphqlClient.mutate({
  // //   //     mutation: gql`
  // //   //       mutation USER_DESTROY($id: String!) {
  // //   //         iamDestroy(id: $id)
  // //   //       }
  // //   //     `,

  // //   //     variables: {
  // //   //       id
  // //   //     }
  // //   //   })
  // //   //   return response.data.iamDestroy
  // //   // }
  // // //#endregion

  // //#region [ Query APIs ]
  // static async find(id) {
  //   // const response = await graphqlClient.query({
  //   //   query: gql`
  //   //     query USER_FIND($id: String!) {
  //   //       iamFind(id: $id) {
  //   //         id
  //   //         email
  //   //         userName
  //   //         phoneNumber
  //   //         avatar
  //   //         listedProductsCount
  //   //         authenticationUid
  //   //         deviceTokens
  //   //         lang
  //   //         role
  //   //         status
  //   //         pagesAccess
  //   //         notification
  //   //         createdBy
  //   //         createdAt
  //   //         updatedBy
  //   //         updatedAt
  //   //       }
  //   //     }
  //   //   `,
  //   //   variables: {
  //   //     id
  //   //   }
  //   // })
  //   // return response.data.iamFind
  // }

  // /**
  //  * Retrieve admins with pagination
  //  * @param {Object[]} filter
  //  * @param {String} filter.key
  //  * @param {String} filter.value
  //  * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith'} filter.operator
  //  * @param {Object} pagination
  //  * @param {Boolean} pagination.firstPage
  //  * @param {Int} pagination.limit
  //  * @param {JSON} pagination.doc
  //  * @param {'next'|'prev'} pagination.action
  //  * @param {'asc'|'desc'} pagination.sortType
  //  * @param {String} pagination.orderBy
  //  * @returns
  //  */
  // static async fetchAdmins(filter, pagination) {
  //   // const response = await graphqlClient.query({
  //   //   query: gql`
  //   //     query USER_LIST_USERS(
  //   //       $filter: [Filter]!,
  //   //       $pagination: PaginationInput!
  //   //     ) {
  //   //       listAdmins(filter: $filter, pagination: $pagination) {
  //   //         id
  //   //         email
  //   //         userName
  //   //         phoneNumber
  //   //         avatar
  //   //         pagesAccess
  //   //         role
  //   //         deviceTokens
  //   //         status
  //   //         lang
  //   //         createdBy
  //   //         createdAt
  //   //         updatedBy
  //   //         updatedAt
  //   //       }
  //   //     }
  //   //   `,
  //   //   variables: {
  //   //     filter,
  //   //     pagination
  //   //   }
  //   // })
  //   // return response.data.listAdmins
  // }

  // //#endregion
}
