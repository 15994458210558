/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
const SideMenu = () => import('@/layouts/side-menu/Main.vue')
const ErrorPage = () => import('@/views/error-page/Main.vue')
const Error403Page = () => import('@/views/error-page/error-403-page.vue')
const Error404Page = () => import('@/views/error-page/error-404-page.vue')
const Error500Page = () => import('@/views/error-page/error-500-page.vue')

const Login = () => import('@/views/auth/signin-page.vue')
const ForgotPassword = () => import('@/views/auth/forgot-password.vue')
const Profile = () => import('@/views/profile/profile.vue')
const ChangePassword = () => import('@/views/profile/change-password.vue')

const UsersListPage = () => import('@/views/users/user-list-page.vue')
const UserViewPage = () => import('@/views/users/user-view-page.vue')
const UserFormPage = () => import('@/views/users/user-form-page.vue')

const RequestsListPage = () => import('@/views/requests/requests-list-page.vue')

const Menus = () => import('@/views/menu/menus.vue')

const routes = [
  {
    path: '',
    redirect: '/users',
    component: SideMenu,
    meta: { auth: true },
    children: [
      {
        name: 'users',
        path: '/users',
        // alias: '',
        component: UsersListPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        name: 'userView',
        path: 'users/:id',
        props: true,
        component: UserViewPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        name: 'userNew',
        path: 'users/new',
        props: true,
        component: UserFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        name: 'userEdit',
        path: 'users/:id/edit',
        props: true,
        component: UserFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        name: 'requests',
        path: '/requests',
        component: RequestsListPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        name: 'menus',
        path: '/menus',
        component: Menus,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        name: 'profile',
        path: 'profile',
        component: Profile,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        name: 'changePassword',
        path: 'change-password',
        component: ChangePassword,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      }
    ]
  },
  {
    name: 'login',
    path: '/auth/login',
    component: Login,
    meta: { unauth: true }
  },
  {
    name: 'forgotPassword',
    path: '/auth/forgot-password',
    component: ForgotPassword,
    meta: { unauth: true }
  },
  {
    name: 'errorPage',
    path: '/error-page',
    component: ErrorPage
  },
  {
    name: 'error403',
    path: '/403',
    component: Error403Page
  },
  {
    name: 'error404',
    path: '/404',
    component: Error404Page
  },
  {
    name: 'error500',
    path: '/500',
    component: Error500Page
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
  // { path: '*', redirect: '/404' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

import authGuard from '@/router/auth-guard'
router.beforeResolve(async (to, from, next) => {
  await authGuard.beforeRouteEnter(to, from, next)
})

// import authGuardMixin from '@/auth/guards/auth-guard-mixin'
// import unauthGuardMixin from '@/auth/guards/unauth-guard-mixin'
// import notEmptyPermissionsGuardMixin from '@/auth/guards/not-empty-permissions-guard-mixin'
// import { UsersIcon } from '@zhuowenli/vue-feather-icons'
// // import { mixins } from '@/auth/guards'
// router.beforeEach((to, from, next) => {
//   // if (to.name) {
//   //   ProgressBar.start();
//   // }

//   next()
// })

// router.beforeResolve(async (to, from, next) => {
//   await authGuardMixin.beforeRouteEnter(to, from, next)
//   await unauthGuardMixin.beforeRouteEnter(to, from, next)
//   await notEmptyPermissionsGuardMixin.beforeRouteEnter(to, from, next)
// })
// router.beforeEach(async (to, from, next) => {
//   console.log('To =', to)
//   console.log('From =', from)
//   next()
//   // await authGuardMixin.beforeRouteEnter(to, from, next)
//   // await unauthGuardMixin.beforeRouteEnter(to, from, next)
//   // await notEmptyPermissionsGuardMixin.beforeRouteEnter(to, from, next)

//   // for (let index = 0; index < guardMixin.length; index++) {
//   //   const mixin = guardMixin[index]
//   //   await mixin.beforeRouteEnter(to, from, next)
//   // }
//   // mixins.forEach(async mixin => {
//   //   await mixin.beforeRouteEnter(to, from, next)
//   // });
// })

// router.beforeEach(async (to, from, next) => {
//   console.log('To =', to)
//   console.log('From =', from)
//   // next()
//   await authGuardMixin.beforeRouteEnter(to, from, next)
// })
// router.beforeEach(async (to, from, next) => {
//   console.log('To =', to)
//   console.log('From =', from)
//   next()
//   await unauthGuardMixin.beforeRouteEnter(to, from, next)
// })
// router.beforeEach(async (to, from, next) => {
//   console.log('To =', to)
//   console.log('From =', from)
//   next()
//   await notEmptyPermissionsGuardMixin.beforeRouteEnter(to, from, next)
// })

export function getPages(pages = routes) {
  const routesPages = []
  for (let i = 0; i < pages.length; i++) {
    const route = pages[i]
    if (route.name) {
      routesPages.push({ name: route.name, checked: false })
    }

    if (route.children && route.children.length) {
      const result = getPages(route.children, 0)
      routesPages.push(...result)
    }
  }
  return routesPages
}
export default router
