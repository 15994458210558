const state = () => {
  return {
    menu: [
      {
        icon: 'UsersIcon',
        pageName: 'users',
        title: 'Users'
      },
      {
        icon: 'ArchiveIcon',
        pageName: 'requests',
        title: 'Requests'
      },
      {
        icon: 'MenuIcon',
        pageName: 'menus',
        title: 'Menus'
      }
    ]
  }
}

const getters = {
  menu: state => state.menu
}
const mutations = {}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
