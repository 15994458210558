/* eslint-disable */
import { UserService } from '@/store/user/user-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'

const INITIAL_PAGE_SIZE = 10
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  // orderBy: 'createdAt',
  sortType: 'desc'
  // action: 'next',
  // firstPage: true,
  // doc: {}
}

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: {},
    currentPage: 1,
    pagination: INITIAL_PAGINATION_INPUT,
    sorter: {},
    table: null
  },

  getters: {
    loading: state => state.loading,
    exportLoading: state => state.exportLoading,
    rows: state => state.rows || [],
    count: state => state.count,
    hasRows: (_, getters) => getters.count > 0,
    filter: state => state.filter,
    currentPage: state => state.currentPage,
    // table: state => state.table,
    // orderBy: state => {
    //   const sorter = state.sorter

    //   if (!sorter) {
    //     return null
    //   }

    //   if (!sorter.prop) {
    //     return null
    //   }

    //   let direction = sorter.order === 'descending' ? 'DESC' : 'ASC'

    //   return `${sorter.prop}_${direction}`
    // },
    limit: state => {
      const pagination = state.pagination
      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE
      }
      return pagination.pageSize
    },
    // offset: state => {
    //   const pagination = state.pagination

    //   if (!pagination || !pagination.pageSize) {
    //     return 0
    //   }

    //   const currentPage = pagination.currentPage || 1

    //   return (currentPage - 1) * pagination.pageSize
    // },
    pagination: (state, getters) => {
      return {
        ...state.pagination,
        currentPage: state.currentPage,
        total: getters.count,
        showSizeChanger: true
      }
    },

    selectedRows: state => {
      return state.table ? state.table.selection : []
    }
  },

  mutations: {
    RESETED(state) {
      state.rows = []
      state.count = 0
      state.loading = false
      state.filter = {}
      state.pagination = INITIAL_PAGINATION_INPUT
      state.sorter = {}
      state.currentPage = 1
      // state.type = null
      if (state.table) {
        state.table.clearSelection()
      }
    },

    // UNSELECT_ALL(state) {
    //   if (state.table) {
    //     state.table.clearSelection()
    //   }
    // },

    // TABLE_MOUNTED(state, payload) {
    //   state.table = payload
    // },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || INITIAL_PAGINATION_INPUT
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage =
        payload && payload > 0 ? payload : state.currentPage || 1
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT

      state.currentPage = state.currentPage || 1
      state.pagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
      }
    },
    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {}
    },
    FETCH_STARTED(state, payload) {
      state.loading = true
      // if (state.table) {
      //   state.table.clearSelection()
      // }
      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.pagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.pagination
          : {}
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false
      state.rows = payload.rows
      state.count = payload.count
    },

    FETCH_ERROR(state) {
      state.loading = false
      state.rows = []
      state.count = 0
    },

    // EXPORT_STARTED(state) {
    //   state.exportLoading = true
    // },

    // EXPORT_SUCCESS(state) {
    //   state.exportLoading = false
    // },

    // EXPORT_ERROR(state) {
    //   state.exportLoading = false
    // },

    REMOVE_ALL_SELECTED_STARTED(state) {
      state.loading = true
    },

    REMOVE_ALL_SELECTED_ERROR(state) {
      state.loading = false
    },

    REMOVE_ALL_SELECTED_SUCCESS(state) {
      if (state.table) {
        state.table.clearSelection()
      }
    },

    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true
    },

    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false
    },

    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      state.loading = false
      if (state.table) {
        state.table.clearSelection()
      }
    }
    // UPDATE_USER_TYPE(state, payload) {
    //   state.type = payload
    // }
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL')
    },

    // doMountTable({ commit }, table) {
    //   console.log(table)
    //   commit('TABLE_MOUNTED', table)
    // },

    async doReset({ commit, dispatch }) {
      commit('RESETED')
      return dispatch('doFetch')
    },

    // doChangePagination({ commit, getters, dispatch }, pagination) {
    //   commit('PAGINATION_CHANGED', pagination);
    //   const filter = getters.filter;
    //   dispatch('doFetch', { filter, keepPagination: true });
    // },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize)
      const filter = getters.filter
      const pagination = getters.pagination
      dispatch('doFetch', { filter, pagination, keepPagination: true })
    },

    doChangePaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
      // const filter = getters.filter;
      // dispatch('doFetch', { filter, keepPagination: true });
    },
    async doFetchNextPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      if (rows.length) {
        const currentPage = getters.currentPage || 1
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const lastDocument = rows[rows.length - 1]
        const pagination = {
          ...previousPagination,
          action: 'next',
          firstPage: false,
          doc: lastDocument
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage + 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetchPreviousPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      const currentPage = getters.currentPage || 1
      if (currentPage > 1 && rows.length) {
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const firstDocument = rows[0]
        const pagination = {
          ...previousPagination,
          action: 'prev',
          firstPage: false,
          doc: firstDocument
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage - 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetch(
      { commit, getters },
      { filter, pagination, keepPagination = true } = {}
    ) {
      try {
        // console.log('{{FILTER FETCH', filter)
        commit('FETCH_STARTED', { filter, pagination, keepPagination })

        const PaginationInput = {
          limit: pagination.limit || getters.pagination.limit,
          // doc: pagination.doc || getters.pagination.doc,
          // action: pagination.action || getters.pagination.action,
          // orderBy: pagination.orderBy || getters.pagination.orderBy,
          sortBy: pagination.sortBy || getters.pagination.sortBy
          // firstPage: pagination.firstPage
        }
        // console.log('{{FILTER FETCH', getters.filter)
        const response = await UserService.fetchUsers(
          getters.filter,
          PaginationInput
        )
        // console.log('{{RESPONSE}}', response)
        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count
        })
      } catch (error) {
        console.log('ERROR =>', error)
        Errors.handle(error)
        commit('FETCH_ERROR')
      }
    },
    async doToggleUserStatus({ getters, commit, dispatch }, { id, disabled }) {
      try {
        commit('CHANGE_STATUS_SELECTED_STARTED')

        await UserService.toggleStatus(id, disabled)

        commit('CHANGE_STATUS_SELECTED_SUCCESS')

        disabled
          ? Message.success(i18n('iam.doDisableAllSuccess'))
          : Message.success(i18n('iam.doEnableAllSuccess'))
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      }
    }
    // async doDisableAllSelected({ getters, commit, dispatch }, ids) {
    //   try {
    //     // const selectedRows = getters.selectedRows;
    //     const selectedRows = ids

    //     commit('CHANGE_STATUS_SELECTED_STARTED')

    //     // await UserService.disable(selectedRows.map((user) => user.id));
    //     await UserService.disable(selectedRows)

    //     commit('CHANGE_STATUS_SELECTED_SUCCESS')

    //     Message.success(i18n('iam.doDisableAllSuccess'))

    //     dispatch('doFetch', {
    //       filter: getters.filter,
    //       pagination: getters.pagination
    //     })
    //   } catch (error) {
    //     Errors.handle(error)
    //     commit('CHANGE_STATUS_SELECTED_ERROR')
    //     dispatch('doFetch', {
    //       filter: getters.filter,
    //       pagination: getters.pagination
    //     })
    //   }
    // },
    // async doEnableAllSelected({ commit, getters, dispatch }, ids) {
    //   try {
    //     // const selectedRows = getters.selectedRows;
    //     const selectedRows = ids

    //     commit('CHANGE_STATUS_SELECTED_STARTED')

    //     // await UserService.enable(selectedRows.map((user) => user.id));
    //     await UserService.enable(selectedRows)

    //     commit('CHANGE_STATUS_SELECTED_SUCCESS')

    //     Message.success(i18n('iam.doEnableAllSuccess'))

    //     dispatch('doFetch', {
    //       filter: getters.filter,
    //       pagination: getters.pagination
    //     })
    //   } catch (error) {
    //     Errors.handle(error)

    //     commit('CHANGE_STATUS_SELECTED_ERROR')

    //     dispatch('doFetch', {
    //       filter: getters.filter,
    //       pagination: getters.pagination
    //     })
    //   }
    // }

    // async doRemoveAllSelected({ commit, getters, dispatch }) {
    //   try {
    //     const selectedRows = getters.selectedRows;

    //     commit('REMOVE_ALL_SELECTED_STARTED');

    //     await UserService.remove(
    //       selectedRows.map((row) => row.email),
    //       [],
    //       true,
    //     );

    //     dispatch('REMOVE_ALL_SELECTED_SUCCESS');

    //     Message.success(i18n('iam.doRemoveAllSelectedSuccess'));

    //     const filter = getters.filter;
    //     dispatch('doFetch', { filter });
    //   } catch (error) {
    //     Errors.handle(error);

    //     commit('REMOVE_ALL_SELECTED_ERROR');

    //     const filter = getters.filter;
    //     dispatch('doFetch', { filter });
    //   }
    // },
    // async doDestroy({ commit }, id) {
    //   try {
    //     await UserService.destroy(id);
    //     Message.success(i18n('entities.admin.destroy.success'));
    //   } catch (error) {
    //     console.log(commit);
    //     Errors.handle(error);
    //   }
    // },
  }
}
